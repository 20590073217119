@mixin standard-vertical-gap() {
  margin-top: 0;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
