// Add ellipsis (...) to text that are longer than its container.

%truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  @extend %truncate;
}
