.mm-menu {
  background-color: $color-primary;
  color: $color-white;
  .mm-panels {
    .mm-panel {
      position: absolute;
      top: 40px;
      left: 60px;
      right: 60px;
    }
    .mm-navbar {
      .mm-title {
        color: $color-white;
      }
    }
  }
  .mm-listview {
    font-size: 1.6rem;
    li {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    li:after {
      border: 0;
    }
    li:nth-last-child(4) {
      border-bottom: 1px dotted;
      border-color: $color-primary-light;
      padding-bottom: 40px;
    }
    li:nth-last-child(3) {
      padding-top: 40px;
    }
    a {
      color: $color-white;
      padding: 0;
    }
    a:hover {
      color: $color-primary-light;
    }
  }
}
.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
  background: rgba(255, 255, 255, 0);
}
