.step-headings {
  counter-reset: step-heading;
  .step-heading:before {
    content: "Step " counter(step-heading) ": ";
    counter-increment: step-heading;
  }
  &.upper-roman .step-heading:before {
    content: "Step " counter(step-heading, upper-roman) ": ";
  }
  &.upper-alpha .step-heading:before {
    content: "Step " counter(step-heading, upper-alpha) ": ";
  }
}
