.layout-sidebar {
  display: flex;
  padding: 60px;

  > .content-wrapper {
    padding: 0;
    &:first-child {
      flex: 1 1 auto;
    }
    &:last-child {
      margin-left: 60px;
      flex: 0 0 300px;
    }
  }

  // Responsive methods
  @mixin respond-1 {
    display: block;
    padding: 60px;

    > .content-wrapper {
      @include standard-vertical-gap;
      &:last-child {
        margin-left: 0;
      }
      .contact-info {
        background-color: $color-white;
        padding: 20px;
        border-left: solid 5px $color-primary;
        margin: 60px 0px;
      }
    }
  }

  @mixin respond-2 {
    padding: 32px;
  }

  // Responsive breakpoints
  @include mq($until: mq-10) {
    @include respond-1;
  }
  @include mq($until: mq-8) {
    @include respond-2;
  }
}
