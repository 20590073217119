// Default single column block
.block-default {
  > .content-wrapper {
    max-width: 1024px;
    margin: 0 auto;
    padding: 60px;
  }

  &.offset-top-bar {
    > .content-wrapper {
      margin-top: 59px;
    }
  }

  // Full-width option that removes the max-width capitalize
  &.full-width {
    > .content-wrapper {
      max-width: none;
    }
  }

  // Wide-width option
  &.wide-width {
    > .content-wrapper {
      max-width: 1440px;
    }
  }

  // Narrow-width option
  &.narrow-width {
    > .content-wrapper {
      max-width: 768px;
    }
  }

  // No padding option
  &.no-padding {
    > .content-wrapper {
      padding: 0;
    }
  }

  // Center-aligned option
  &.center-aligned {
    text-align: center;
  }

  // White Background
  &.theme-white {
    background-color: $color-white;
  }

  // Inverted theme primary option
  &.inverted-theme-primary {
    background: $color-inverted-background url("../img/green-bg.jpg");
    color: $color-inverted-text;

    h1 {
      color: $color-inverted-text;
    }

    h2 {
      color: $color-inverted-text;
    }

    a {
      color: $color-inverted-text;

      &:hover {
        opacity: 0.8;
      }
    }

    input,
    textarea,
    select {
      border-bottom: 1px solid $color-primary-light;
      color: $color-inverted-text;
    }
  }

  // Inverted theme secondary option
  &.inverted-theme-secondary {
    background-color: $color-dark-grey;
    color: $color-inverted-text;

    h1,
    h2 {
      color: $color-inverted-text;
    }

    a {
      color: $color-yellow;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  // Fixed
  &.fixed-top-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;

    @extend %elevation-fixed-bar;
  }

  // Responsive methods
  @mixin respond-1 {
    > .content-wrapper {
      padding: 32px;
    }
  }

  // Responsive breakpoints
  @include mq($until: mq-8) {
    @include respond-1;
  }
}
