/*
 * Color settings
 */

// Descriptive color names (Add your color codes here)

$color-material-teal-50: #e0f2f1; //50
$color-material-teal-100: #b2dfdb; //100
$color-material-teal-200: #80cbc4; //200
$color-material-teal-300: #4db6ac; //300
$color-material-teal-400: #26a69a; //400
$color-material-teal-500: #009688; //500 (main)
$color-material-teal-600: #00897b; //600
$color-material-teal-700: #00796b; //700
$color-material-teal-800: #00695c; //800
$color-material-teal-900: #004d40; //900
$color-material-teal-a100: #a7ffeb; //A100
$color-material-teal-a200: #64ffda; //A200
$color-material-teal-a400: #1de9b6; //A400
$color-material-teal-a700: #00bfa5; //A700

$color-material-amber-50: #fff8e1; //50
$color-material-amber-100: #ffecb3; //100
$color-material-amber-200: #ffe082; //200
$color-material-amber-300: #ffd54f; //300
$color-material-amber-400: #ffca28; //400
$color-material-amber-500: #ffc107; //500 (main)
$color-material-amber-600: #ffb300; //600
$color-material-amber-700: #ffa000; //700
$color-material-amber-800: #ff8f00; //800
$color-material-amber-900: #ff6f00; //900
$color-material-amber-a100: #ffe57f; //A100
$color-material-amber-a200: #ffd740; //A200
$color-material-amber-a400: #ffc400; //A400
$color-material-amber-a700: #ffab00; //A700

$color-material-red-50: #ffebee; //50
$color-material-red-100: #ffcdd2; //100
$color-material-red-200: #ef9a9a; //200
$color-material-red-300: #e57373; //300
$color-material-red-400: #ef5350; //400
$color-material-red-500: #f44336; //500 (main)
$color-material-red-600: #e53935; //600
$color-material-red-700: #d32f2f; //700
$color-material-red-800: #c62828; //800
$color-material-red-900: #b71c1c; //900
$color-material-red-a100: #ff8a80; //A100
$color-material-red-a200: #ff5252; //A200
$color-material-red-a400: #ff1744; //A400
$color-material-red-a700: #d50000; //A700

$color-material-grey-50: #fafafa; // 50
$color-material-grey-100: #f5f5f5; // 100
$color-material-grey-200: #eeeeee; // 200
$color-material-grey-300: #e0e0e0; // 300
$color-material-grey-400: #bdbdbd; // 400
$color-material-grey-500: #9e9e9e; // 500
$color-material-grey-600: #757575; // 600
$color-material-grey-700: #616161; // 700
$color-material-grey-800: #424242; // 800
$color-material-grey-900: #212121; // 900

$color-black: #000000; // black
$color-white: #ffffff; // white

$color-genoa: #0f6a5e;
$color-patina: #56958d;
$color-flamingo: #f15a24;
$color-school-bus-yellow: #e5c600;
$color-fun-blue: #18479f;
// Abstract color names (Give easy-to-remember nicknames for your colors to directly use in your stylesheets)

$color-red: $color-material-red-500;
$color-light-red: $color-material-red-100;
$color-teal: $color-material-teal-500;
$color-amber: $color-material-amber-800;
$color-light-amber: $color-material-amber-100;
$color-light-grey: $color-material-grey-50;
$color-semi-light-grey: $color-material-grey-200;
$color-grey: $color-material-grey-400;
$color-semi-dark-grey: $color-material-grey-700;
$color-dark-grey: $color-material-grey-800;
$color-light-orange: $color-material-amber-a100;

$color-bs-green: $color-genoa;
$color-bs-green-light: $color-patina;
$color-orange: $color-flamingo;
$color-yellow: $color-school-bus-yellow;
$color-blue: $color-fun-blue;
$color-bs-grey: $color-material-grey-600;
// Important colors (Colors where you have a darker and lighter version for effects like mouse-hover)

$color-primary: $color-bs-green;
$color-primary-light: $color-bs-green-light;
$color-secondary: $color-orange;
$color-general-text: $color-semi-dark-grey;
$color-general-gery: $color-grey;
$color-general-separator: $color-material-grey-200;

//$color-primary-light            : lighten($color-primary, 20%);
$color-primary-dark: darken($color-primary, 20%);
$color-secondary-light: lighten($color-secondary, 20%);
$color-secondary-dark: darken($color-secondary, 20%);

$color-general-background: $color-light-grey;
$color-general-background-hover: $color-semi-light-grey;
$color-general-heading: $color-grey;
$color-general-link: $color-secondary;
$color-general-link-hover: $color-secondary-light;
$color-general-secondary-text: $color-material-grey-500;
$color-general-error: $color-red;

$color-inverted-background: $color-primary;
$color-inverted-heading: $color-white;
$color-inverted-text: $color-white;
$color-inverted-link: $color-yellow;
$color-inverted-link-hover: $color-secondary-light;
$color-inverted-separator: $color-material-teal-300;
$color-inverted-secondary-text: $color-material-grey-500;
$color-inverted-error: $color-yellow;
