.request-pending-overlay {
    @extend %elevation-modal;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-white;
    opacity: 0.7;

    .request-pending-overlay-active & {
        display: block;
    }
}
