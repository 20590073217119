.scroll-to-top {
  position: fixed;
  right: 26px;
  bottom: 90px;
  @extend %elevation-fixed-bar;

  // Controlling the display
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  pointer-events: none;
  .scroll-not-top & {
    opacity: 1;
    pointer-events: auto;
  }

  // Hiding the scroll-to-top button mobile
  .mobile & {
    display: none;
  }
}
