@font-face {
  font-family: 'Beamstyle-Icons';
  src:  url('../fonts/Beamstyle-Icons/fonts/Beamstyle-Icons.eot?1uxxwb');
  src:  url('../fonts/Beamstyle-Icons/fonts/Beamstyle-Icons.eot?1uxxwb#iefix') format('embedded-opentype'),
    url('../fonts/Beamstyle-Icons/fonts/Beamstyle-Icons.ttf?1uxxwb') format('truetype'),
    url('../fonts/Beamstyle-Icons/fonts/Beamstyle-Icons.woff?1uxxwb') format('woff'),
    url('../fonts/Beamstyle-Icons/fonts/Beamstyle-Icons.svg?1uxxwb#Beamstyle-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

.bs-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Beamstyle-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bs-icon-display-list01:before {
  content: "\e900";
}
.bs-icon-display-thumb01:before {
  content: "\e901";
}
.bs-icon-arrow10-right:before {
  content: "\e902";
}
.bs-icon-arrow10-top:before {
  content: "\e903";
}
.bs-icon-arrow10-bottom:before {
  content: "\e904";
}
.bs-icon-arrow10-left:before {
  content: "\e905";
}
.bs-icon-download02:before {
  content: "\e996";
}
.bs-icon-language02:before {
  content: "\e995";
}
.bs-icon-search02:before {
  content: "\e994";
}
.bs-icon-arrow01-up:before {
  content: "\e906";
}
.bs-icon-arrow01-down:before {
  content: "\e907";
}
.bs-icon-arrow01-left:before {
  content: "\e908";
}
.bs-icon-arrow01-right:before {
  content: "\e909";
}
.bs-icon-arrow02-up:before {
  content: "\e90a";
}
.bs-icon-arrow02-down:before {
  content: "\e90b";
}
.bs-icon-arrow02-left:before {
  content: "\e90c";
}
.bs-icon-arrow02-right:before {
  content: "\e90d";
}
.bs-icon-arrow03-up:before {
  content: "\e90e";
}
.bs-icon-arrow03-down:before {
  content: "\e90f";
}
.bs-icon-arrow03-left:before {
  content: "\e910";
}
.bs-icon-arrow03-right:before {
  content: "\e911";
}
.bs-icon-arrow04-up:before {
  content: "\e912";
}
.bs-icon-arrow04-down:before {
  content: "\e913";
}
.bs-icon-arrow04-left:before {
  content: "\e914";
}
.bs-icon-arrow04-right:before {
  content: "\e915";
}
.bs-icon-arrow05-up:before {
  content: "\e916";
}
.bs-icon-arrow05-down:before {
  content: "\e917";
}
.bs-icon-arrow05-left:before {
  content: "\e918";
}
.bs-icon-arrow05-right:before {
  content: "\e919";
}
.bs-icon-arrow06-up:before {
  content: "\e91a";
}
.bs-icon-arrow06-down:before {
  content: "\e91b";
}
.bs-icon-arrow06-left:before {
  content: "\e91c";
}
.bs-icon-arrow06-right:before {
  content: "\e91d";
}
.bs-icon-arrow07-top:before {
  content: "\e91e";
}
.bs-icon-arrow07-down:before {
  content: "\e91f";
}
.bs-icon-arrow07-left:before {
  content: "\e920";
}
.bs-icon-arrow07-right:before {
  content: "\e921";
}
.bs-icon-arrow08-up:before {
  content: "\e987";
}
.bs-icon-arrow08-down:before {
  content: "\e98c";
}
.bs-icon-arrow08-left:before {
  content: "\e98d";
}
.bs-icon-arrow08-right:before {
  content: "\e993";
}
.bs-icon-arrow09-up:before {
  content: "\e988";
}
.bs-icon-arrow09-down:before {
  content: "\e989";
}
.bs-icon-arrow09-left:before {
  content: "\e98a";
}
.bs-icon-arrow09-right:before {
  content: "\e98b";
}
.bs-icon-attachment01:before {
  content: "\e922";
}
.bs-icon-box01:before {
  content: "\e923";
}
.bs-icon-broken01:before {
  content: "\e924";
}
.bs-icon-calendar01:before {
  content: "\e925";
}
.bs-icon-catalogue01:before {
  content: "\e926";
}
.bs-icon-change01:before {
  content: "\e927";
}
.bs-icon-change02:before {
  content: "\e928";
}
.bs-icon-chat01:before {
  content: "\e929";
}
.bs-icon-chat02:before {
  content: "\e92a";
}
.bs-icon-close01:before {
  content: "\e92b";
}
.bs-icon-contact01:before {
  content: "\e92c";
}
.bs-icon-correct-and-wrong01-correct:before {
  content: "\e92d";
}
.bs-icon-correct-and-wrong01-wrong:before {
  content: "\e92e";
}
.bs-icon-correct-and-wrong02-correct:before {
  content: "\e92f";
}
.bs-icon-correct-and-wrong02-wrong:before {
  content: "\e930";
}
.bs-icon-cursor01:before {
  content: "\e931";
}
.bs-icon-deal01:before {
  content: "\e932";
}
.bs-icon-deal02:before {
  content: "\e933";
}
.bs-icon-add-person01:before {
  content: "\e934";
}
.bs-icon-delete01:before {
  content: "\e935";
}
.bs-icon-doc01:before {
  content: "\e936";
}
.bs-icon-download01:before {
  content: "\e937";
}
.bs-icon-add-doc01:before {
  content: "\e938";
}
.bs-icon-duplicate:before {
  content: "\e939";
}
.bs-icon-edit01:before {
  content: "\e93a";
}
.bs-icon-email01:before {
  content: "\e93b";
}
.bs-icon-email02:before {
  content: "\e93c";
}
.bs-icon-email03:before {
  content: "\e93d";
}
.bs-icon-expand-and-collapse01-collapse:before {
  content: "\e93e";
}
.bs-icon-expand-and-collapse01-expand:before {
  content: "\e93f";
}
.bs-icon-expand-and-collapse02-collapse:before {
  content: "\e940";
}
.bs-icon-expand-and-collapse02-expand:before {
  content: "\e941";
}
.bs-icon-expand-and-collapse03-collapse:before {
  content: "\e942";
}
.bs-icon-expand-and-collapse03-expand:before {
  content: "\e943";
}
.bs-icon-export01:before {
  content: "\e944";
}
.bs-icon-facebook01:before {
  content: "\e945";
}
.bs-icon-facebook02:before {
  content: "\e946";
}
.bs-icon-facebook03:before {
  content: "\e947";
}
.bs-icon-facebook04:before {
  content: "\e98e";
}
.bs-icon-blog01:before {
  content: "\e98f";
}
.bs-icon-news01:before {
  content: "\e991";
}
.bs-icon-facebook-messenger01:before {
  content: "\e992";
}
.bs-icon-favorite01:before {
  content: "\e948";
}
.bs-icon-filter01:before {
  content: "\e949";
}
.bs-icon-folder01:before {
  content: "\e94a";
}
.bs-icon-folder02:before {
  content: "\e94b";
}
.bs-icon-home01:before {
  content: "\e94c";
}
.bs-icon-instagram01:before {
  content: "\e94d";
}
.bs-icon-instagram02:before {
  content: "\e94e";
}
.bs-icon-language01:before {
  content: "\e94f";
}
.bs-icon-like01:before {
  content: "\e950";
}
.bs-icon-linkedin01:before {
  content: "\e951";
}
.bs-icon-linkedin02:before {
  content: "\e952";
}
.bs-icon-location01:before {
  content: "\e953";
}
.bs-icon-lock01:before {
  content: "\e954";
}
.bs-icon-lock02:before {
  content: "\e955";
}
.bs-icon-login01-login:before {
  content: "\e956";
}
.bs-icon-login01-logout:before {
  content: "\e957";
}
.bs-icon-login02-login:before {
  content: "\e958";
}
.bs-icon-login02-logout:before {
  content: "\e959";
}
.bs-icon-menu01:before {
  content: "\e95a";
}
.bs-icon-move01:before {
  content: "\e95b";
}
.bs-icon-pdf01:before {
  content: "\e95c";
}
.bs-icon-pdf02:before {
  content: "\e95d";
}
.bs-icon-phone01:before {
  content: "\e95e";
}
.bs-icon-photo01:before {
  content: "\e95f";
}
.bs-icon-photo02:before {
  content: "\e960";
}
.bs-icon-play01-backward:before {
  content: "\e961";
}
.bs-icon-play01-fast-backward:before {
  content: "\e962";
}
.bs-icon-play01-fast-forward:before {
  content: "\e963";
}
.bs-icon-play01-forward:before {
  content: "\e964";
}
.bs-icon-play01-pause:before {
  content: "\e965";
}
.bs-icon-play01-stop:before {
  content: "\e966";
}
.bs-icon-play02-backward:before {
  content: "\e967";
}
.bs-icon-play02-fast-backward:before {
  content: "\e968";
}
.bs-icon-play02-fast-forward:before {
  content: "\e969";
}
.bs-icon-play02-forward:before {
  content: "\e96a";
}
.bs-icon-play02-pause:before {
  content: "\e96b";
}
.bs-icon-play02-stop:before {
  content: "\e96c";
}
.bs-icon-print01:before {
  content: "\e96d";
}
.bs-icon-search01:before {
  content: "\e96e";
}
.bs-icon-selected01:before {
  content: "\e96f";
}
.bs-icon-setting01:before {
  content: "\e970";
}
.bs-icon-setting02:before {
  content: "\e971";
}
.bs-icon-shopping01:before {
  content: "\e972";
}
.bs-icon-shopping02:before {
  content: "\e973";
}
.bs-icon-shopping03:before {
  content: "\e974";
}
.bs-icon-sound01-off:before {
  content: "\e975";
}
.bs-icon-sound01-on:before {
  content: "\e976";
}
.bs-icon-sound02-off:before {
  content: "\e977";
}
.bs-icon-sound02-on:before {
  content: "\e978";
}
.bs-icon-stop01:before {
  content: "\e979";
}
.bs-icon-time01:before {
  content: "\e97a";
}
.bs-icon-twitter01:before {
  content: "\e97b";
}
.bs-icon-twitter02:before {
  content: "\e97c";
}
.bs-icon-twitter03:before {
  content: "\e990";
}
.bs-icon-upload01:before {
  content: "\e97d";
}
.bs-icon-video01:before {
  content: "\e97e";
}
.bs-icon-view01:before {
  content: "\e97f";
}
.bs-icon-view02:before {
  content: "\e980";
}
.bs-icon-wechat01:before {
  content: "\e981";
}
.bs-icon-wechat02:before {
  content: "\e982";
}
.bs-icon-weibo01:before {
  content: "\e983";
}
.bs-icon-weibo02:before {
  content: "\e984";
}
.bs-icon-win01:before {
  content: "\e985";
}
.bs-icon-youtube01:before {
  content: "\e986";
}
.bs-icon-youtube02:before {
  content: "\e997";
}
.bs-icon-youtube03:before {
  content: "\e998";
}
.bs-icon-youtube04:before {
  content: "\e999";
}
.bs-icon-youtube05:before {
  content: "\e99a";
}
.bs-icon-zoom01-in:before {
  content: "\e99b";
}
.bs-icon-zoom01-out:before {
  content: "\e99c";
}
