.map-banner {
  position: relative;
  max-height: 600px;
  min-height: 300px;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }

  > .map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  // Class modifiers

  &.animation {
    // Initial State (before web fonts are loaded, before animation)
    & {
      > .map {
        @extend .animated;
        opacity: 0;
      }
    }

    // Activated State (web fonts are loaded & "activate-animation" class exists)
    &.animate {
      > .map {
        @extend .fadeIn;
        animation-delay: 0.1s;
      }
    }
  }
}
