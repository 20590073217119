%elevation-dragged-or-holding-item {
  z-index: 200;
}

%elevation-dialog-or-picker {
  z-index: 100;
}

%elevation-modal {
  z-index: 90;
}

%elevation-drawer {
  z-index: 80;
}

%elevation-fixed-bar {
  z-index: 70;
}

%elevation-sidebar {
  z-index: 60;
}

%elevation-menu {
  z-index: 50;
}

%elevation-button {
  z-index: 30;
}

%elevation-card {
  z-index: 10;
}

%elevation-canvas {
  z-index: 0;
}
