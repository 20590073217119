// Clear first child top and last child bottom margin.

%trim-child-margins {
  hr + & {
    margin-top: 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.trim-child-margins {
  @extend %trim-child-margins;
}
