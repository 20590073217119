.counter {
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  .counter-set {
    min-width: 180px;
    vertical-align: top;
    margin: 20px;
    display: inline-block;
    .counter-icon {
      float: left;
    }
    .counter-info {
      float: left;
      margin-top: 7px;
      .number {
        font-size: 4.6rem;
        line-height: 1;
        font-weight: bold;
        color: $color-primary;
        text-align: left;
      }
      label {
        font-size: 1.4rem;
        line-height: 1.6;
        margin-top: 0;
        text-align: left;
      }
    }
  }
}

// Responsive methods
@mixin respond-1 {
}

@mixin respond-2 {
}

@mixin respond-3 {
  .counter {
    .counter-set {
      margin: 10px;
    }
  }
}

// Responsive breakpoints
@include mq($until: mq-12) {
  @include respond-1;
}
@include mq($until: mq-8) {
  @include respond-2;
}
@include mq($until: mq-4) {
  @include respond-3;
}
