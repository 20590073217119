// http://stackoverflow.com/questions/396145/how-to-vertically-center-a-div-for-all-browsers
// http://daverupert.com/2012/04/uncle-daves-ol-padded-box/

.field {
  @extend .standard-vertical-gap;
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;

  input,
  textarea,
  select {
    &:disabled {
      color: $color-general-gery;
    }
  }

  label,
  input,
  textarea,
  select,
  .error-message {
    margin-bottom: 4px;
    text-align: left;
  }

  input,
  textarea,
  select,
  .error-message {
    width: inherit;
  }

  input::placeholder,
  textarea::placeholder {
    color: $color-general-gery;
  }

  .error-message {
    display: none;
    font-size: 1.2rem;
  }

  // When error class triggers
  &.error {
    color: $color-general-error;

    input,
    textarea,
    select {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $color-general-error;
    }

    .error-message {
      display: block;
    }
  }

  // Inverted theme primary option
  .inverted-theme-primary & {
    input::placeholder,
    textarea::placeholder {
      color: $color-primary-light;
    }

    &.error {
      color: $color-inverted-error;

      input,
      textarea,
      select {
        border-bottom: 1px solid $color-inverted-error;
      }
    }
  }
}

.field.checkbox,
.field.radio {
  legend {
    margin-bottom: 4px;
  }

  label {
    cursor: pointer;
    display: inline-block;
    margin-right: 24px;

    > input[type="checkbox"],
    > input[type="radio"] {
      position: absolute;
      visibility: hidden;
      overflow: hidden;
      width: 0;
      height: 0;

      + span {
        display: inline-block;
        line-height: 34px;
        vertical-align: top;
        font-size: 2rem;

        &::before {
          @extend .material-icons;
          height: 34px;
          line-height: inherit;
          vertical-align: inherit;
          margin-right: 8px;
          float: left;
        }

        > span {
          display: block;
          overflow: auto;
          line-height: inherit;
          vertical-align: inherit;
          text-align: left;
        }
      }

      &:disabled {
        + span {
          color: $color-general-gery;
        }
      }
    }

    > input[type="checkbox"] {
      + span:before {
        content: "check_box_outline_blank";
      }
      &:checked + span:before {
        content: "check_box";
        color: $color-primary;
      }
      &:disabled + span:before {
        color: $color-general-gery;
      }
      &:indeterminate + span:before {
        content: "indeterminate_check_box";
        color: $color-primary;
      }
    }

    > input[type="radio"] {
      + span:before {
        content: "radio_button_unchecked";
      }
      &:checked + span:before {
        content: "radio_button_checked";
        color: $color-primary;
      }
      &:disabled + span:before {
        color: $color-general-gery;
      }
    }
  }

  // When error class triggers
  &.error {
    label {
      color: $color-general-text;
    }
  }
}

.field.file {
  // The field input
  > input {
    display: none;

    // Controlling the display of 2-modes: uploader, showcase
    + .input {
      > .mode.mode {
        display: none;
      }
      > .mode.uploader {
        display: block;
      }
    }
    &:valid + .input {
      > .mode.mode {
        display: none;
      }
      > .mode.showcase {
        display: block;
      }
    }
  }

  .placeholder {
    color: #00ff00;
  }
  // The file input field component to be used by fineuploader
  .input {
    position: relative;
    overflow: hidden;
    border: 1px solid $color-general-gery;
    margin-bottom: 4px;

    // Default content styles for file fields
    .content-wrapper {
      > * {
        @extend .standard-vertical-gap;
        text-align: center;
      }
      .actions a {
        margin: 0 4px;
      }
      img {
        margin: 0;
      }
    }

    .mode {
      position: relative;
      background-color: $color-general-background;

      .placeholder {
        position: relative;

        // CSS to display background image in aspect ratio. (Requires inline styles to work)
        background: transparent no-repeat center center / contain;
        margin: auto;
        height: 0;

        .spinner {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
        }
      }

      .acontent {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .state {
          position: relative;
          height: 100%;

          > .display {
            position: relative;
            @extend %flexbox-center-child;
            height: 100%;

            &.image {
              // CSS to display background image in aspect ratio. (Requires inline styles to work)
              background: transparent no-repeat center center / contain;
              margin: auto;
              height: 0;
            }

            &.overlay {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              background-color: $color-general-gery;
            }
          }

          // Controlling the display of "overlay" on hover inside the states
          > .display.display {
            display: flex;
          }
          > .display.overlay {
            display: none;
          }
          &:hover,
          &.dragging-hover {
            > .display.overlay {
              display: flex;
            }
          }
        }
      }

      // Controlling the display of different states
      .state.dropzone {
        display: none;
      }
      .state.browse {
        display: block;
      }
      .state.progress {
        display: none;
      }
      .state.preview {
        display: block;
      }
      @at-root .dragging & {
        .state.dropzone {
          display: block;
        }
        .state.browse {
          display: none;
        }
      }
      .state.dragging-hover {
        &.state.dropzone {
          display: block;
        }
        &.state.browse {
          display: none;
        }
      }
      &.in-progress {
        .state.dropzone {
          display: none;
        }
        .state.browse {
          display: none;
        }
        .state.progress {
          display: block;
        }
      }
    }
  }

  // When error class triggers
  &.error {
    color: $color-general-error;
    .input {
      border: 1px solid $color-general-error;
    }
  }

  ////////////////////////
  ///////////////////////////////////

  /////////////////////////////////////////////

  /*
        // Aspect-ratio placeholder used in replace of <img> in case there isn't one.
        .placeholder{
            background-color:$color-light-grey;
            margin:0 auto;
            .aspect-ratio{ width:100%; }
        }

        .content, .overlay{
            position:absolute;
            top:0;
            bottom:0;
            right:0;
            left:0;
            display: flex;
            flex-wrap:wrap;
            align-items: center;
            justify-content: center;
            padding:24px;
            text-align:center;
            > *{
                margin:4px;
            }
            > .description{
                width:100%;
            }
        }

        // Controls displaying of overlay.  All types of hovers should display the overlay.
        .overlay{
            background-color:$color-semi-light-grey;
            display:none;
        }
        &:hover .overlay,
        .dropzone.dragging-hover .overlay{
            display:flex;
        }
        */
}

/*

    /*
    // Multi-Select fields
    &.multi-select{
        label{ font-size:1.2rem; }

        // CSS customized for multiple select list widget "SumoSelect"
        > .SumoSelect{
            display:block;
            @include box-shadow(none);
            @include border-radius(0);

            > .CaptionCont{
                @include box-shadow(inherit);
                @include border-radius(inherit);


                background-color:transparent;
                margin:0;
                padding:5px 5px 6px;
                border:1px solid $color-black-dividers;
                width:inherit;

                > span{
                    padding-bottom:1px;
                    font-size:1.4rem;
                    line-height:2.0;
                    font-style:normal;
                    text-transform:none;
                    color:$color-black-text;

                    + label{
                        margin:7px 0;
                    }
                }
            }

            > .optWrapper.multiple{
                @extend %md-elevation-quick-entry-or-search-bar;
                @include box-shadow(inherit);
                @include border-radius(inherit);
                top:34px;

                > .select-all,
                > .options > li{
                    position:relative; width:auto; height:auto; top:auto; bottom:auto; left:auto; right:auto;
                    vertical-align:middle;
                    margin:0;
                    padding:6px;

                    span {
                        position:inherit; width:inherit; height:inherit; top:inherit; bottom:inherit; left:inherit; right:inherit;
                        display:inline-block; vertical-align:inherit;
                        margin:0 10px;

                        > i{
                            position:inherit; width:inherit; height:inherit; top:inherit; bottom:inherit; left:inherit; right:inherit;
                            display:inherit; vertical-align:inherit;
                            margin:0;
                            border:0;
                            background:none;
                            @include box-shadow(none);

                            &::before{
                                display:inherit; vertical-align:inherit;
                                @extend .material-icons;
                                color:$color-black-text;
                                content:"check_box_outline_blank";
                            }
                        }
                    }

                    label{
                        display:inline-block; vertical-align:inherit;
                        margin:0 10px 0 0;
                        font-size:1.4rem;
                        font-style:normal;
                        color:$color-black-text;
                    }

                    &.sel,
                    &:hover{
                        background-color:$color-theme-light-background;
                    }

                    &.selected span i:before{
                        color:$color-secondary;
                        content:"check_box";
                    }

                    &.partial span i:before{
                        color:$color-secondary;
                        content:"indeterminate_check_box";
                    }
                }
            }

            &:focus > .CaptionCont,
            &:hover > .CaptionCont{
                @include box-shadow(none);
                border-color:$color-black-dividers;
            }
        }
    }
    */
