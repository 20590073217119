.portfolio-banner {
  position: relative;
  min-height: 900px;
  height: calc(100vh);

  > .placeholder {
    height: inherit;
    min-height: inherit;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > .content-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    img {
      display: block;
      margin: auto;
      padding: calc(32px + 60px) 32px 0;
    }
  }

  // Responsive levels & styles
  @mixin respond-1 {
    min-height: 0;
    height: auto;

    > .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    > .content {
      position: relative;

      > .content-wrapper {
        position: relative;
      }
    }
  }

  @mixin respond-2 {
    > .content {
      img {
        padding: calc(8px + 60px) 8px 0;
      }
    }
  }

  // Responsive breakpoints
  @include mq($until: mq-12) {
    @include respond-1;
  }
  @include mq($until: mq-4) {
    @include respond-2;
  }
}
