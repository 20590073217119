// Reference: https://css-tricks.com/examples/ShapesOfCSS/

$pointer-box-height: 50px;
.pointer-box {
  @extend .standard-vertical-gap;
  display: inline-block;
  position: relative;
  padding: 0 10px 0 20px;
  height: $pointer-box-height;
  line-height: $pointer-box-height;
  background-color: $color-primary;
  color: $color-inverted-text;
  text-transform: uppercase;

  &:after {
    content: "";
    position: absolute;
    right: -($pointer-box-height / 2);
    bottom: 0;
    width: 0;
    height: 0;
    border-left: ($pointer-box-height / 2) solid $color-primary;
    border-top: ($pointer-box-height / 2) solid transparent;
    border-bottom: ($pointer-box-height / 2) solid transparent;
  }

  &:hover {
    color: $color-inverted-text;
    opacity: 0.8;
  }

  // When theme is inverted
  .inverted-theme-primary.inverted-theme-primary & {
    // Deliberately increase specificity
    background-color: $color-bs-green-light;
    color: $color-inverted-text;
    &:after {
      border-left: ($pointer-box-height / 2) solid $color-bs-green-light;
    }
  }

  // Various icon options

  &.icon {
    &:before {
      float: left;
      margin-left: -15px;
      font-size: 4.5rem;
      line-height: $pointer-box-height;
    }
  }

  &.location-marker {
    &:before {
      @extend .pointer-box.icon;
      @extend .bs-icon;
      @extend .bs-icon-location01;
    }
  }
}
