// Vertical aligns elements inside current container.

%vertical-align {
  &::before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.vertical-align {
  @extend %vertical-align;
}
