// Makes use of flexbox to center a child div horizontally and vertically

%flexbox-center-child {
  color: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.flexbox-center-child {
  @extend %flexbox-center-child;
}
