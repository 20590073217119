.color-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  justify-content: center;

  a:hover {
    opacity: 0.8;
  }

  > li {
    flex: 1 1 auto;
    width: 33.33%;
    max-width: 300px;
    padding: 8px;

    .color-card {
      position: relative;

      .background {
        height: 0;
        padding-bottom: 100%;
      }

      .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        > .content-wrapper {
          position: absolute;
          bottom: 0;
          padding: 20px;

          h2 {
            margin: 0;
          }
        }
      }

      &.green,
      &.dark-grey,
      &.yellow,
      &.orange,
      &.blue,
      &.light-grey {
        * {
          color: $color-white;
        }
      }
      &.green .background {
        background-color: $color-bs-green;
      }
      &.dark-grey .background {
        background-color: $color-semi-dark-grey;
      }
      &.yellow .background {
        background-color: $color-yellow;
      }
      &.orange .background {
        background-color: $color-orange;
      }
      &.blue .background {
        background-color: $color-blue;
      }
      &.light-grey .background {
        background-color: $color-semi-light-grey;
      }
    }
  }

  // Responsive methods
  @mixin respond-1 {
    > li {
      width: 50%;

      img {
        width: auto;
      }
    }
  }

  @mixin respond-2 {
    > li {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Responsive breakpoints
  @include mq($until: mq-9) {
    @include respond-1;
  }
  @include mq($until: mq-5) {
    @include respond-2;
  }
}
