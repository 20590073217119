.vertical-split {
  display: flex;
  flex-wrap: wrap;
  padding: 100px 60px;

  @extend .standard-vertical-gap;
  // @extend %flexbox-center-child;

  > .content-wrapper {
    width: calc(50% - 30px);

    &:first-child {
      margin-right: 60px;
    }
  }

  // Responsive methods
  @mixin respond-1 {
    > .content-wrapper {
      width: 100%;

      @include standard-vertical-gap;

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
      }
    }
  }

  @mixin respond-2 {
    padding: 32px;
  }

  // Responsive breakpoints
  @include mq($until: mq-10) {
    @include respond-1;
  }

  @include mq($until: mq-8) {
    @include respond-2;
  }

  // Specific components

  // Left figure
  &.left-figure {
    padding: 0;
  }

  // Right figure
  &.right-figure {
    padding: 0;

    // Responsive methods
    @mixin respond-1 {
      flex-direction: column-reverse;

      > .content-wrapper {
        &:first-child {
          margin-bottom: 0;
        }

        &:last-child {
          margin-bottom: 20px;
        }
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-10) {
      @include respond-1;
    }
  }
}
