.video-embedder {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  @extend .standard-vertical-gap;

  > * {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.youtube {
    padding-bottom: 56.25%;
  }
}
