$grid-gutter-default: 20px;
.grid {
  @extend .standard-vertical-gap;
  > .grid-container {
    display: flex;

    > .content-wrapper {
      flex: 1 1 auto;
      margin-left: $grid-gutter-default / 2;
      margin-right: $grid-gutter-default / 2;
    }
  }

  // Specific type components

  &.fields-6-6 {
    > .grid-container {
      > .content-wrapper {
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }

    // Responsive methods
    @mixin respond-1 {
      > .grid-container {
        display: block;
        > .content-wrapper {
          @include standard-vertical-gap;
          &:nth-child(n) {
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-11) {
      @include respond-1;
    }
  }

  $grid-gutter: $grid-gutter-default;
  $grid-columns: 2;
  &.field-options-6-6 {
    > .grid-container {
      flex-wrap: wrap;
      > .content-wrapper {
        width: calc(
          #{100% / $grid-columns} - #{$grid-gutter * ($grid-columns - 1) / $grid-columns}
        );
        max-width: calc(
          #{100% / $grid-columns} - #{$grid-gutter * ($grid-columns - 1) / $grid-columns}
        );
        &:nth-child(#{$grid-columns}n + 1) {
          margin-left: 0;
        }
        &:nth-child(#{$grid-columns}n) {
          margin-right: 0;
        }
      }
    }

    // Responsive methods
    @mixin respond-1 {
      > .grid-container {
        display: block;
        > .content-wrapper {
          &:nth-child(n) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-4) {
      @include respond-1;
    }
  }
}
