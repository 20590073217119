header {
  border-top: 2px $color-primary solid;
  border-bottom: 1px $color-general-separator solid;
  background-color: $color-white;
  opacity: 0.9;
  transition: all 2s ease;

  // When the page is scrolled at its top, remove transparent effect.
  .scroll-top & {
    opacity: 1;
  }

  > .content-wrapper {
    max-width: none;

    .desktop,
    .mobile {
      @extend %clearfix;

      > * {
        display: block;
        float: right;
      }

      .logo {
        float: left;
        display: block;
        margin: 8px;

        img {
          display: block;
          padding: 0 8px;
        }
      }
    }

    .desktop {
      nav {
        ul {
          @extend %clearfix;

          padding: 0;
          list-style: none;

          > li {
            float: left;

            a {
              display: block;
              line-height: 24px;
              padding: 16px;
              transition: color 0.3s linear;

              color: $color-general-text;
              &:hover {
                color: $color-secondary;
              }

              &.active {
                color: $color-secondary;
              }

              &.highlight {
                color: $color-primary;
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .mobile {
      .mobile-nav-trigger {
        padding: 12px;
        color: $color-primary;
        font-size: 3.2rem;
      }

      nav {
        display: none;
      }
    }
  }

  // Controlling the display of header nav when web fonts have finished loading (regardless success or error)
  > .content-wrapper {
    .desktop,
    .mobile {
      nav {
        @extend .animated;
        opacity: 0;
        .wf-active &,
        .wf-inactive & {
          @extend .fadeIn;
        }
      }
    }
  }

  // Controlling mobile responsive display of header elements
  > .content-wrapper .desktop {
    display: block;
  }
  > .content-wrapper .mobile {
    display: none;
  }
  @mixin respond-1 {
    > .content-wrapper .desktop {
      display: none;
    }
    > .content-wrapper .mobile {
      display: block;
    }
  }
  @include mq($until: mq-10) {
    @include respond-1;
  }
}
