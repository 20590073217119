// Component base

// Core

html {
  font-size: 10px; // Do not change.  Hard-coded base font-size of 10px
  font-weight: 300;
  box-sizing: border-box;
}

// Basic rules for locales
body,
button,
input,
select,
textarea {
  html[lang="en"] & {
    font-family: $font-family-en-primary;
  }
  html[lang="zh-TW"] & {
    font-family: $font-family-zh-TW-primary;
  }
  html[lang="zh-CN"] & {
    font-family: $font-family-zh-CN-primary;
  }
}
// Rule for locale overriding via "lang" attribute
body {
  *[lang="en"] {
    &,
    button,
    input,
    select,
    textarea {
      font-family: $font-family-en-primary;
    }
  }
  *[lang="zh-TW"] {
    &,
    button,
    input,
    select,
    textarea {
      font-family: $font-family-zh-TW-primary;
    }
  }
  *[lang="zh-CN"] {
    &,
    button,
    input,
    select,
    textarea {
      font-family: $font-family-zh-CN-primary;
    }
  }
}

body {
  background-color: $color-general-background;
  color: $color-general-text;
  font-size: 1.5rem;
  line-height: 1.6;
}

// Default box-sizing

*,
*:before,
*:after {
  box-sizing: inherit;
}

// Default element spacing

.standard-vertical-gap, // This is for components that need the "standard-vertical-gap" behavior
h1, h2, h3, h4, h5, h6, p, hr, img, ul, ol, dl, table, button, fieldset, legend, input, a[role="button"], textarea, select, figure {
  margin-top: 0;
  margin-bottom: 20px;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

// Headings

h1,
h2,
h3,
h4,
h5,
h6,
span {
  font-weight: 300;
}

h1 {
  font-size: 4.6rem;
  font-weight: 100;
  line-height: 1.3;
  margin-top: (10px * 4.6 * 2); //64px;
  margin-bottom: (10px * 4.6 * 1.2 / 2); //19px;
  color: $color-general-heading;

  * {
    font-weight: 100;
  }

  &.secondary {
    color: $color-primary;
  }
}

h2 {
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1.4;
  margin-top: (10px * 1.8 * 2); //36px;
  margin-bottom: (10px * 1 * 2); //40px;
  color: $color-primary;

  &.secondary {
    color: $color-general-heading;
  }
}

h3 {
  font-size: 2.4rem;
  line-height: 1.4;
  margin-top: (10px * 2.4 * 2); //40px;
  margin-bottom: (10px * 2.4 * 1.4 / 2); //15px;
  color: $color-general-heading;
}

h4 {
  font-size: 1.8rem;
  line-height: 1.5;
  margin-top: (10px * 1.8 * 2); //32px;
  margin-bottom: (10px * 1.8 * 1.5 / 2); //12px;
  color: $color-general-text;
}

h5 {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-top: (10px * 1.4 * 2); //28px;
  margin-bottom: (10px * 1.4 * 1.6 / 2); //11px;
}

h6 {
  font-size: 1.2rem;
  line-height: 1.7;
  margin-top: (10px * 1.2 * 2); //28px;
  margin-bottom: (10px * 1.2 * 1.6 / 2); //11px;
}

// Typography

p {
  &.large {
    font-size: 1.8rem;
    line-height: 1.5;
  }

  &.xlarge {
    font-size: 2.6rem;
    font-weight: 100;
    line-height: 1.4;
  }
  &.left-aligned {
    text-align: left;
  }
}

a {
  text-decoration: none;
  color: $color-general-link;

  &:hover {
    color: $color-general-link-hover;
  }

  // Class modifiers

  &.subtle {
    color: $color-general-text;
    &:hover {
      opacity: 0.8;
    }
  }
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid $color-general-separator;
  padding-bottom: 20px;
  margin: 0 auto;
  &.thin {
    width: 100px;
  }
}

em {
  color: $color-primary;
  font-style: normal;
}

strong {
}

b {
}

i {
}

address {
  font-style: normal;
  margin-bottom: 20px;
}

// Images

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}

// Lists

ul,
ol {
  li {
  }
}

ul {
  padding-left: 25px;
}

ol {
}

dl {
  > * {
    margin: 0 0 4px;
  }

  dt {
    font-size: 1.2rem;
  }

  dd {
    border: 1px solid transparent;
    line-height: 2;
  }
}

// Tables

table {
  tr {
  }

  th,
  td {
  }

  thead {
    th {
    }
  }

  tbody {
  }
}

// Forms

label {
  font-size: 2.4rem;
}

label,
input,
textarea,
select {
  display: block;
}

input,
textarea,
select {
  box-sizing: border-box;
  outline: none;
  padding: 0;
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid $color-general-separator;
  font-size: 2.6rem;
  font-weight: 300;
  line-height: 1.3;
  color: $color-general-text;

  &:focus {
    //outline:1px solid $color-primary;
  }
}

fieldset {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  border: 0;
}

legend {
  font-size: 2.4rem;
  line-height: 1.5;
}

textarea {
  min-height: 65px;
  max-height: 600px;
  font-size: 1.8rem;
  line-height: 1.5;
}

select {
  height: 34px;
}

button,
input[type="submit"],
input[type="reset"],
input[type="button"],
a[role="button"] {
  display: inline-block;
  border: 0;
  outline: none;
  cursor: pointer;
  padding: 15px 30px;
  min-width: 200px;
  min-height: 50px;
  max-width: 100%;
  line-height: 1.4;
  vertical-align: middle;
  background-color: $color-primary;
  color: $color-white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 300;

  &:hover {
    color: $color-white;
    background-color: $color-primary-light;
  }

  &:focus,
  &:active {
    background-color: $color-primary-light;
    //opacity:0.2;
  }
}

.center {
  text-align: center;
}
