.error-banner {
  text-align: center;

  h1 {
    @extend .standard-vertical-gap;
    line-height: 1;
    font-size: 3rem;
    em {
      font-size: 10rem;
      color: $color-inverted-text;
      display: block;
    }
  }

  .details {
    @extend .standard-vertical-gap;
    > span {
      display: block;
    }
  }
}
