.wonderwall {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  > .tile {
    flex: 1 1 auto;
    width: 100%;
    max-width: 100%;
    position: relative;

    img {
      margin-bottom: 0;
      width: 100%;
    }

    .content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @extend %flexbox-center-child;

      > .content-wrapper {
        padding: 16px;
        text-align: center;

        > * {
          display: block;
          color: $color-white;
        }

        span {
          font-size: 1.5rem;
          margin-bottom: 8px;
        }

        h2 {
          margin: 0;
          text-transform: uppercase;
        }
      }
    }

    &.animation {
      // Initial State (before web fonts are loaded, before animation)

      &.first,
      &.second,
      &.third {
        @extend .animated;

        opacity: 0; /* stylelint-disable-line declaration-empty-line-before */
        transform: translateY(100px);
      }

      // Activated State (web fonts are loaded & "activate-animation" class exists)
      .wf-active &.animate,
      .wf-inactive &.animate {
        @extend .fadeIn;

        transition-timing-function: ease;
        transform: translateY(0);

        &.first {
          transition-duration: 0.5s;
          animation-delay: 0s;
        }

        &.second {
          transition-duration: 1s;
          animation-delay: 0.25s;
        }

        &.third {
          transition-duration: 1.5s;
          animation-delay: 0.5s;
        }
      }
    }
  }

  // Class modifiers

  &.show-content-on-hover {
    > .tile {
      .content {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
        transition: all 0.5s ease-in-out;
      }

      &:hover {
        .content {
          opacity: 1;
          @extend %flexbox-center-child;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &.dim-image-on-hover {
    background-color: $color-primary;

    > .tile {
      img {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
      }

      &:hover {
        img {
          opacity: 0.1;
        }
      }
    }
  }

  &.color-image-on-hover {
    > .tile {
      img {
        filter: grayscale(100%);
      }

      &:hover {
        img {
          filter: grayscale(10%);
        }
      }
    }
  }

  &.center-aligned {
    justify-content: center;
  }

  // Specific components

  &.portfolios {
    > .tile {
      width: 25%;
      max-width: 25%;
    }

    // Responsive methods
    @mixin respond-1 {
      > .tile {
        width: 50%;
        max-width: 50%;
      }
    }

    @mixin respond-2 {
      > .tile {
        width: 100%;
        max-width: 100%;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-11) {
      @include respond-1;
    }
    @include mq($until: mq-6) {
      @include respond-2;
    }
  }

  &.gallery {
    > .tile {
      width: 50%;
      max-width: 50%;
    }

    // Responsive methods
    @mixin respond-1 {
      > .tile {
        width: 100%;
        max-width: 100%;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-6) {
      @include respond-1;
    }
  }

  &.clients {
    > .tile {
      width: 25%;
      max-width: 25%;
    }

    // Responsive methods
    @mixin respond-1 {
      > .tile {
        width: 33.33%;
        max-width: 33.33%;

        img {
          width: auto;
        }
      }
    }

    @mixin respond-2 {
      > .tile {
        width: 50%;
        max-width: 50%;
      }
    }

    @mixin respond-3 {
      > .tile {
        width: 100%;
        max-width: 100%;
      }
    }

    // Responsive breakpoints
    @include mq($until: mq-10) {
      @include respond-1;
    }
    @include mq($until: mq-6) {
      @include respond-2;
    }
    @include mq($until: mq-4) {
      @include respond-3;
    }
  }
}
